import { useRouter } from "next/router";
import Footer from "./footer_kongsi";
import Header05 from "./header/Header05";
import BidsModal from "./modal/bidsModal";
import BuyModal from "./modal/buyModal";
import Wallet_modal from "./modal/wallet_modal";

export default function Layout({ children, generalConfig, homePage }) {
  const route = useRouter();
  // header start

  return (
    <>
      <Header05 homePage={homePage} />
      <Wallet_modal />
      <BidsModal />
      <BuyModal />
      <main>{children}</main>
      <Footer generalConfig={generalConfig} homePage={homePage} />
    </>
  );
}
