import { MetaMaskProvider } from "metamask-react";
import { ThemeProvider } from "next-themes";
import { useRouter } from "next/router";
import { useRef } from "react";
import { Provider } from "react-redux";
import Meta from "../components/Meta";
import UserContext from "../components/UserContext";
import Layout from "../components/layout";
import { store } from "../redux/store";
import StrapiApi from "../services/StrapiApi";
import "../styles/globals.css";

function MyApp({ Component, pageProps, generalConfig, homePage }) {
  const router = useRouter();
  const pid = router.asPath;
  const scrollRef = useRef({
    scrollPos: 0,
  });

  return (
    <>
      <Meta title="Home 1" />

      <Provider store={store}>
        <ThemeProvider enableSystem={true} attribute="class">
          <MetaMaskProvider>
            <UserContext.Provider value={{ scrollRef: scrollRef }}>
              {pid === "/login" ? (
                <Component {...pageProps} />
              ) : (
                <Layout generalConfig={generalConfig} homePage={homePage}>
                  <Component {...pageProps} />
                </Layout>
              )}
            </UserContext.Provider>
          </MetaMaskProvider>
        </ThemeProvider>
      </Provider>
    </>
  );
}

MyApp.getInitialProps = async () => {
  const mainApi = StrapiApi.getInstance();

  const generalConfig = await mainApi.getGeneralConfig();
  const homePage = await mainApi.getHome();
  console.log("here home page", homePage);

  return { generalConfig, homePage };
};

export default MyApp;
