import formatNumberStr from "../utils/formatNumberStr";
import HttpClient from "./HttpClientBase";

export interface GeneralConfigListResponse {
  data: { id: number; attributes: GeneralConfig; meta: StrapiMeta }[];
}

interface Image {
  id: string;
  alternativeText: string;
  ext: string;
  height: number;
  width: number;
  name: string;
  url: string;
}

export interface StrapiImage extends Image {
  formats: {
    medium: Image;
    small: Image;
    thumbnail: Image;
  };
  url: string;
}

export interface StrapiMeta {
  pagination: {
    page: number;
    pageCount: number;
    pageSize: number;
    total: number;
  };
}

export interface SocialMedia {
  company: string;
  link: string;
  followers: number;
}

export interface Brand {
  name: string;
  icon: {
    data: {
      attributes: StrapiImage;
    };
  };
  link: string;
}

export interface PageTitleObject {
  homePage: string;
}

export interface GeneralConfigObject {
  companyName: string;
  address: string;
  phoneNumber: string;
  email: string;
  total_views: number;
  total_followers: number;
  total_creators: number;
  company_social_media: SocialMedia[];
  brand: Brand[];
  footerDescriptions: string;
  terms: string;
  privacy: string;
}

export interface PageTitleResponse {
  data: {
    attributes: PageTitleObject;
  };
  meta: StrapiMeta;
}

export interface GeneralConfigResponse {
  data: {
    attributes: GeneralConfigObject;
  };
  meta: StrapiMeta;
}

export interface CreatorProfileObject {
  name: string;
  descriptions: string;
  field: string;
  photo: {
    data: {
      attributes: StrapiImage;
    };
  };
  username: string;
  social_media_link: SocialMedia[];
}
export interface CreatorProfile {
  field: string;
  name: string;
  descriptions: string;
  photo: ImagePathDict;
  socialMedias: SocialMedia[];
  username: string;
}
export interface CreatorPage {
  creatorProfiles: CreatorProfile[];
}

export interface CreatorPageResponse {
  data: {
    attributes: {
      creator_profile: CreatorProfileObject[];
    };
  };
  meta: StrapiMeta;
}

export interface ServiceDetailObject {
  title: string;
  descriptions: string;
  icon: {
    data: {
      attributes: StrapiImage;
    };
  };
}

export interface ServiceDetail {
  title: string;
  descriptions: string;
  icon: ImagePathDict;
}

export interface StrapiHomePage {
  kongsi_logo: {
    data: {
      attributes: StrapiImage;
    };
  };
  favicon: {
    data: {
      attributes: StrapiImage;
    };
  };
  headline_image: {
    data: {
      attributes: StrapiImage;
    };
  };
  about_us_icon: {
    data: {
      attributes: StrapiImage;
    };
  };
  descriptions: string;
  header: string;
  service: {
    title: string;
    descriptions: string;
    serviceDetail: ServiceDetailObject[];
  };
}

export interface Service {
  title: string;
  descriptions: string;
  serviceDetail: ServiceDetail[];
}

export interface HomePage {
  favicon: ImagePathDict;
  kongsi_logo: ImagePathDict;
  headline_image: ImagePathDict;
  about_us_icon: ImagePathDict;
  descriptions: string;
  header: string;
  service: Service;
}

export interface HomePageResponse {
  data: {
    attributes: StrapiHomePage;
  };
  meta: StrapiMeta;
}

export interface MediaPage {
  company_mission: string;
}

export interface MediaPageResponse {
  data: {
    attributes: MediaPage;
  };
  meta: StrapiMeta;
}

export interface ImagePathDict {
  medium: string;
  small: string | null;
  thumbnail: string | null;
  url: string | null;
}

export interface PageTitle {
  homePage: string;
}

export interface GeneralConfig {
  companyName: string;
  totalCreators: string;
  totalFollowers: string;
  totalViews: string;
  email: string;
  companySocialMedias: SocialMedia[];
  brands: {
    name: string;
    link: string;
    logo: ImagePathDict;
  }[];
  address: string;
  phoneNumber: string;
  footerDescriptions: string;
  terms: string;
  privacy: string;
}

const prepareImagePathDict = (strapiImage: StrapiImage): ImagePathDict => {
  const strapiUrl = process.env.STRAPI_URL;
  let mediumPath = null;
  let smallPath = null;
  let thumbnailPath = null;
  if (strapiImage.formats != null) {
    const {
      medium = null,
      small = null,
      thumbnail = null,
    } = strapiImage.formats;
    mediumPath = medium ? `${strapiUrl}${medium.url}` : null;
    smallPath = small ? `${strapiUrl}${small.url}` : null;
    thumbnailPath = thumbnail ? `${strapiUrl}${thumbnail.url}` : null;
  }

  return {
    medium: mediumPath,
    small: smallPath,
    thumbnail: thumbnailPath,
    url: `${strapiUrl}${strapiImage.url}`,
  };
};

class StrapiApi extends HttpClient {
  private static classInstance?: StrapiApi;
  public constructor() {
    super("https://strapi.kongsi.co/api");
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new StrapiApi();
    }
    return this.classInstance;
  }

  public getPageTitle = async (): Promise<PageTitle> => {
    const pageTitleResponse = await this.instance.get<PageTitleResponse>(
      "/page-title/?populate=homePage"
    );

    const { homePage } = pageTitleResponse.data.attributes;

    return {
      homePage,
    };
  };

  public getGeneralConfig = async (): Promise<GeneralConfig> => {
    const generalConfigResponse =
      await this.instance.get<GeneralConfigResponse>(
        "/general-config/?populate=company_social_media,brand.icon,companyName"
      );

    const {
      companyName,
      total_creators,
      total_followers,
      total_views,
      email,
      company_social_media,
      brand,
      address,
      phoneNumber,
      footerDescriptions,
      terms,
      privacy,
    } = generalConfigResponse.data.attributes;

    const brands = brand.map((brandObject) => {
      const { name, link, icon } = brandObject;
      return {
        name,
        link,
        logo: prepareImagePathDict(icon.data.attributes),
      };
    });

    return {
      totalCreators: formatNumberStr(total_creators),
      totalFollowers: formatNumberStr(total_followers),
      totalViews: formatNumberStr(total_views),
      email: email,
      companySocialMedias: company_social_media,
      brands: brands,
      address,
      phoneNumber,
      footerDescriptions,
      terms,
      privacy,
      companyName,
    };
  };

  public getCreator = async (): Promise<CreatorPage> => {
    const creatorPageResponse = await this.instance.get<CreatorPageResponse>(
      "/creator/?populate=creator_profile.social_media_link,creator_profile.photo"
    );
    const { creator_profile } = creatorPageResponse.data.attributes;
    const creatorProfiles = creator_profile.map((creatorProfile) => {
      const { name, descriptions, social_media_link, photo, field, username } =
        creatorProfile;
      return {
        name,
        descriptions,
        photo: prepareImagePathDict(photo.data.attributes),
        socialMedias: social_media_link,
        field,
        username,
      };
    });
    return {
      creatorProfiles,
    };
  };

  public getHome = async (): Promise<HomePage> => {
    const homePageResponse = await this.instance.get<HomePageResponse>(
      "/home/?populate=kongsi_logo,headline_image,about_us_icon,service,service.serviceDetail.icon,favicon"
    );
    const {
      descriptions,
      kongsi_logo,
      about_us_icon,
      headline_image,
      header,
      service: serviceObject,
      favicon,
    } = homePageResponse.data.attributes;

    const {
      title: serviceTitle,
      descriptions: serviceDescriptions,
      serviceDetail: serviceDetailObjects,
    } = serviceObject;

    const serviceDetails = serviceDetailObjects.map((serviceDetailObject) => {
      const { title, descriptions, icon } = serviceDetailObject;
      return {
        title,
        descriptions,
        icon: prepareImagePathDict(icon.data.attributes),
      };
    });

    return {
      descriptions,
      header,
      favicon: prepareImagePathDict(favicon.data.attributes),
      kongsi_logo: prepareImagePathDict(kongsi_logo.data.attributes),
      about_us_icon: prepareImagePathDict(about_us_icon.data.attributes),
      headline_image: prepareImagePathDict(headline_image.data.attributes),
      service: {
        title: serviceTitle,
        descriptions: serviceDescriptions,
        serviceDetail: serviceDetails,
      },
    };
  };

  public getMedia = () =>
    this.instance.get<MediaPageResponse>("/media/?populate=company_mission");
}

export default StrapiApi;
