import commaNumber from "comma-number";

export function abbreviateNumber(number: number): string {
  const symbols = ["", "k", "M", "G", "T", "P", "E"];
  // what tier? (determines SI symbol)
  var tier = (Math.log10(Math.abs(number)) / 3) | 0;

  // if zero, we don't need a suffix
  if (tier == 0) return `${number}`;

  // get suffix and determine scale
  var suffix = symbols[tier];
  var scale = Math.pow(10, tier * 3);

  // scale the number
  var scaled = number / scale;

  // format number and add suffix
  return scaled.toFixed(1) + suffix;
}
export default function formatNumberStr(value: number): string {
  if (value >= 100000) {
    return abbreviateNumber(value);
  } else {
    return commaNumber(value);
  }
}
